<template>
    <div>
        <div class="modal-header">
            <div class="modal-title">
                <div>同步菜单({{ synsTenantMenu.fullName }})</div>
            </div>
        </div>
        <a-row>
            <a-col :span="6">
                <q-label label="租户"></q-label>
            </a-col>
            <a-col :span="18">
                <a-select
                    style="width: 100%"
                    allowClear
                    v-model="TenantId"
                    @change="TenantIdChange"
                >
                    <a-select-option v-for="item in TenantList" :key="item.id">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="24">
                <a-spin :spinning="treeLoading">
                    <a-card>
                        <a-row :gutter="8">
                            <a-col :span="24">
                                <span class="left">
                                    <a-icon type="share-alt" />
                                    {{ l("模块") }}
                                </span>
                            </a-col>
                            <a-col :span="24"
                                ><a-button
                                    v-if="this.ParentId != 0"
                                    @click="onreload"
                                    style="color: black"
                                >
                                    主菜单
                                </a-button>
                                <a-button v-else type="primary">
                                    主菜单
                                </a-button>
                            </a-col>
                        </a-row>
                        <a-tree
                            class="draggable-tree"
                            :tree-data="TreeData"
                            @select="selectTree"
                        >
                        </a-tree>
                    </a-card> </a-spin
            ></a-col>
        </a-row>
        <div class="modal-footer">
            <a-button @click="close">
                <a-icon type="close-circle" />
                {{ l("Cancel") }}
            </a-button>
            <a-button :type="'primary'" @click="save">
                <a-icon type="save" />
                保存
            </a-button>
        </div>
    </div>
</template>
<script>
import { ModalComponentBase } from "../../../../shared/component-base";
import {
    SynsTenantMenuDto,
    TenantServiceProxy,
    ModuleServiceProxy,
} from "../../../../shared/service-proxies";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
    name: "syns-add",
    mixins: [ModalComponentBase],
    data() {
        return {
            zh_CN,
            synsTenantMenuDto: new SynsTenantMenuDto(),
            synsTenantMenu: [],
            TenantList: [],
            TenantId: 1,
            treeLoading: false,
            TreeData: [],
            ParentId: 0,
            moduleId: undefined,
        };
    },
    created() {
        this.fullData(); // 模态框必须,填充数据到data字段
        this._tenantServiceProxy = new TenantServiceProxy(
            this.$apiUrl,
            this.$api
        );
        this._moduleServiceProxy = new ModuleServiceProxy(
            this.$apiUrl,
            this.$api
        );

        this.getData();
        this.getTreeData();
    },
    mounted() {},
    methods: {
        getData() {
            this._tenantServiceProxy
                .getTenantList()
                .finally(() => {})
                .then((res) => {
                    console.log("租户1：", res);
                    this.TenantList = res;
                    console.log("租户2：", this.TenantList);
                });
        },
        save(record) {
            if (this.synsTenantMenu.fullName == "全部") {
                this.synsTenantMenuDto.copyAllMenu = true;
            } else {
                this.synsTenantMenuDto.copyAllMenu = false;
                this.synsTenantMenuDto.moudleId = this.synsTenantMenu.id;
            }
            this.synsTenantMenuDto.tenantlId = this.TenantId;
            this.synsTenantMenuDto.parentModuleId = this.ParentId;
            this._tenantServiceProxy
                .copyMenuToTenantUser(this.synsTenantMenuDto)
                .finally(() => {})
                .then((res) => {
                    this.$notification["success"]({
                        message: this.l("SavedSuccessfully"),
                    });
                    this.success(true);
                });
        },
        onreload() {
            this.ParentId = 0;
            console.log(this.ParentId);
        },
        /**
         * 树选择
         */
        selectTree(key) {
            this.moduleId = key.length > 0 ? key[0] : 0;
            this.ParentId = this.moduleId;
            console.log(this.ParentId);
        },
        /**
         * 获取数结构
         */
        getTreeData() {
            this.treeLoading = true;
            this._moduleServiceProxy
                .getModuleTree(2, this.TenantId)
                .finally(() => {
                    this.treeLoading = false;
                })
                .then((res) => {
                    this.TreeData = res;
                });
        },
        TenantIdChange() {
            this.getTreeData();
        },
    },
};
</script>