<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="loading">
			<!-- 标题 -->
			<div class="modal-header">
				<div class="modal-title">
					<a-icon type="medicine-box" />
					<span v-if="id">{{ l('EditModule') }}</span>
					<span v-else>{{ l('CreateModule') }}</span>
				</div>
			</div>
			<div class="step">
				<!--                步骤条-->
				<a-row>
					<a-steps :current="current" size="small">
						<a-step key="0" title="基础信息" />
						<a-step key="1" title="按钮信息" />
						<a-step key="2" title="列表信息" />
						<a-step key="3" title="表单信息" />
					</a-steps>
				</a-row>
			</div>
			<div style="margin-top: 40px">
				<!--                基础信息-->
				<div v-show="current == 0">
					<div style="min-height: 300px">
						<a-row>
							<a-col :span="12">
								<a-row>
									<a-col :span="6">
										<q-label required label="编号" :margin-bottom="10"></q-label>
									</a-col>
									<a-col :span="14">
										<a-input :placeholder="l('ModuleCode')" v-model="moduleEntity.enCode" />
									</a-col>
								</a-row>
							</a-col>
							<a-col :span="12">
								<a-row>
									<a-col :span="6">
										<q-label required label="模块名称" :margin-bottom="10"></q-label>
									</a-col>
									<a-col :span="14">
										<a-input :placeholder="l('ModuleName')" v-model="moduleEntity.fullName" />
									</a-col>
								</a-row>
							</a-col>
						</a-row>
						<a-row>
							<a-col :span="12">
								<a-row>
									<a-col :span="6">
										<q-label required label="上级" :margin-bottom="10"></q-label>
									</a-col>
									<a-col :span="14">
										<a-tree-select
											style="width: 100%"
											:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
											:tree-data="moduleData"
											v-model="parentId"
											allowClear
											:placeholder="l('ModuleParent')"
										/>
									</a-col>
								</a-row>
							</a-col>
							<a-col :span="12">
								<a-row>
									<a-col :span="6">
										<q-label required label="图标" :margin-bottom="10"></q-label>
									</a-col>
									<a-col :span="14">
										<div class="group-input">
											<div class="group-text" @click="selectIcon">
												<IconFont
													:type="moduleEntity.icon"
													style="font-size:30px"
													v-if="moduleEntity.icon"
												></IconFont>
											</div>
											<div class="group-icon" v-if="moduleEntity.icon">
												<a title="清空" @click="moduleEntity.icon = undefined"
													><a-icon type="close"
												/></a>
											</div>
											<div class="group-icon" v-else>
												<a><a-icon type="smile"/></a>
											</div>
										</div>
									</a-col>
								</a-row>
							</a-col>
						</a-row>
						<a-row>
							<a-col :span="12">
								<a-row>
									<a-col :span="6">
										<q-label required label="方式" :margin-bottom="10"></q-label>
									</a-col>
									<a-col :span="14">
										<a-select
											default-value="1"
											style="width: 100%"
											v-model="moduleEntity.target"
											@change="targetchage"
										>
											<a-select-option value="1">
												iframe
											</a-select-option>
											<a-select-option value="2">
												open
											</a-select-option>
											<a-select-option value="3">
												expand
											</a-select-option>
										</a-select>
									</a-col>
								</a-row>
							</a-col>
							<a-col :span="12">
								<a-row>
									<a-col :span="6">
										<q-label required label="排序" :margin-bottom="10"></q-label>
									</a-col>
									<a-col :span="14">
										<a-input-number
											:placeholder="l('ModelSort')"
											:min="0"
											style="width: 100%"
											v-model="moduleEntity.sortCode"
										/>
									</a-col>
								</a-row>
							</a-col>
						</a-row>

						<a-row>
							<a-col :span="3"><q-label required label="地址" :margin-bottom="10"></q-label></a-col>
							<a-col :span="19">
								<a-input :placeholder="l('ModuleAddr')" v-model="moduleEntity.urlAddress" />
							</a-col>
						</a-row>
						<a-row>
							<a-col :span="3"><q-label required label="选项" :margin-bottom="10"></q-label></a-col>
							<a-col :span="19">
								<a-row>
									<a-col :span="3">
										<a-checkbox v-model="isMenu" @change="isMenuchange">
											菜单
										</a-checkbox>
									</a-col>
									<a-col :span="3">
										<a-checkbox v-model="allowExpand" @change="allowExpandchange">
											展开
										</a-checkbox>
									</a-col>
									<a-col :span="3">
										<a-checkbox v-model="enabledMark" @change="enabledMarkchange">
											有效
										</a-checkbox>
									</a-col>
								</a-row>
							</a-col>
						</a-row>
						<a-row>
							<a-col :span="3"><q-label required label="选项" :margin-bottom="10"></q-label></a-col>
							<a-col :span="19">
								<a-textarea
									:placeholder="l('ModuleDes')"
									:rows="4"
									v-model="moduleEntity.description"
								/>
							</a-col>
						</a-row>
					</div>
				</div>
				<!--                按钮信息-->
				<div v-show="current == 1">
					<btn-info :tableData="moduleBtnList"></btn-info>
				</div>
				<!--                列表信息-->
				<div v-show="current == 2">
					<columns-info :table-data="columnsInfoList"></columns-info>
				</div>
				<!--                表单信息-->
				<div v-show="current == 3">
					<form-info></form-info>
				</div>
			</div>
			<!-- 按钮 -->
			<div class="modal-footer">
				<a-button :type="'button'" :disabled="prevsaving" @click="prev()">
					{{ l('上一步') }}
				</a-button>
				<a-button @click="next()" :disabled="nextsaving" type="button">
					{{ l('下一步') }}
				</a-button>
				<a-button :type="'primary'" @click="save()">
					<a-icon type="save" />
					{{ l('Save') }}
				</a-button>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import { ModalComponentBase } from '../../../../shared/component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import ModuleInfo from './module-info/module-info';
import BtnInfo from './btn-info/btn-info';
import FormInfo from './form-info/form-info';
import ColumnsInfo from './columns-info/columns-info';
import { CreateOrUpdateModuleInput, ModuleEditDto, ModuleServiceProxy } from '../../../../shared/service-proxies';
import modalHelper from '../../../../shared/helpers/modal/modal-helper';
import IocnModel from '../../../../components/icons/icon-model';

export default {
	name: 'create-or-edit-module',
	components: { ColumnsInfo, FormInfo, BtnInfo },
	mixins: [ModalComponentBase],
	data() {
		return {
			zh_CN,
			id: undefined,
			nextsaving: false,
			prevsaving: true,
			current: 0,
			moduleEntity: new ModuleEditDto(),
			moduleBtnList: [],
			moduleData: [],
			columnsInfoList: [],
			isMenu: undefined,
			allowExpand: undefined,
			enabledMark: undefined,
			parentId: undefined,
		};
	},
	created() {
		this.fullData(); // 模态框必须,填充数据到data字段
		this._moduleServiceProxy = new ModuleServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.getParentTree();
		// this.moduleEntity.target='1';
		// this.targetchage("1");
		if (this.id) {
			this.getData();
		}
	},
	watch: {
		current(res) {
			if (this.current >= 3) {
				this.nextsaving = true;
			} else {
				this.nextsaving = false;
			}
			if (this.current <= 0) {
				this.prevsaving = true;
			} else {
				this.prevsaving = false;
			}
		},
	},
	methods: {
		getData() {
			this.loading = true;
			this._moduleServiceProxy
				.getForEdit(this.id)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.moduleEntity = res.module;
					if (this.moduleEntity.target == 'iframe') {
						this.moduleEntity.target = '1';
					} else if (this.moduleEntity.target == 'expand') {
						this.moduleEntity.target = '3';
					} else {
						this.moduleEntity.target = '2';
					}
					this.parentId = this.moduleEntity.parentId == 0 ? undefined : this.moduleEntity.parentId;
					this.isMenu = this.moduleEntity.isMenu ? true : false;
					this.allowExpand = this.moduleEntity.allowExpand ? true : false;
					this.enabledMark = this.moduleEntity.enabledMark ? true : false;
					this.moduleBtnList = res.moduleButton;
					this.columnsInfoList = res.moduleColumn;
				});
		},

		/**
		 * 下一步
		 */
		async next() {
			//数据验证
			switch (this.current) {
				case 0:
					if (!this.moduleEntity.enCode) {
						return abp.message.warn('请输入编号');
					}
					if (!this.moduleEntity.fullName) {
						return abp.message.warn('请输入名称');
					}
					if (!this.moduleEntity.target) {
						return abp.message.warn('请选择方式');
					}
					if (this.moduleEntity.sortCode != 0 && !this.moduleEntity.sortCode) {
						return abp.message.warn('请输入排序码');
					}

					break;
				case 1:
					break;
				case 2:
					let errStr = undefined;
					await this.checkColumn()
						.then((res) => {})
						.catch((err) => {
							errStr = err;
						});
					if (errStr) {
						return abp.message.warn(errStr);
					}
					break;
			}
			this.current++;
		},
		/**
		 * 列权限验证
		 */
		checkColumn() {
			return new Promise((resolve, reject) => {
				for (let i = 0; i < this.columnsInfoList.length; i++) {
					if (!this.columnsInfoList[i].gridName) {
						reject('第' + (i + 1) + '行表格名称不能为空');
					}
					if (!this.columnsInfoList[i].fullName) {
						reject('第' + (i + 1) + '行名称不能为空');
					}
					if (!this.columnsInfoList[i].enCode) {
						reject('第' + (i + 1) + '行编码不能为空');
					}
					let c = this.columnsInfoList.filter(
						(item, index) =>
							index != i &&
							item.gridName == this.columnsInfoList[i].gridName &&
							item.enCode == this.columnsInfoList[i].enCode
					);
					if (c.length > 0) {
						reject('第' + (i + 1) + '行的编号重复');
					}
				}
				resolve(true);
			});
		},
		/**
		 * 上一步
		 */
		prev() {
			this.current--;
		},
		getParentTree() {
			this.loading = true;
			this._moduleServiceProxy
				.getModuleTree()
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					//重新将对象序列化成树形结构
					this.moduleData = JSON.parse(JSON.stringify(res));
				});
		},
		isMenuchange(e) {
			this.moduleEntity.isMenu = e ? 1 : 0;
		},
		allowExpandchange(e) {
			this.moduleEntity.allowExpand = e ? 1 : 0;
		},
		enabledMarkchange(e) {
			this.moduleEntity.enabledMark = e ? 1 : 0;
		},
		targetchage(e) {
			this.moduleEntity.target = e;
		},
		selectIcon() {
			modalHelper
				.create(
					IocnModel,
					{
						code: this.moduleEntity.icon,
						type: 2,
					},
					{
						width: '600px',
					}
				)
				.subscribe((res) => {
					if (res) {
						this.moduleEntity.icon = res;
					}
				});
		},
		async save() {
			let errStr = undefined;
			await this.checkColumn()
				.then((res) => {})
				.catch((err) => {
					errStr = err;
				});
			if (errStr) {
				return abp.message.warn(errStr);
			}
			let input = new CreateOrUpdateModuleInput();
			if (!this.moduleEntity.parentId) {
				this.moduleEntity.parentId = 0;
			}
			if (this.moduleEntity.target == '1') {
				this.moduleEntity.target = 'iframe';
			} else if (this.moduleEntity.target == '3') {
				this.moduleEntity.target = 'expand';
			} else {
				this.moduleEntity.target = 'open';
			}
			this.moduleEntity.parentId = this.parentId;
			input.module = this.moduleEntity;
			input.moduleButton = this.moduleBtnList;
			input.moduleColumn = this.columnsInfoList;
			this.loading = true;
			this._moduleServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped>
.step {
	margin: -24px;
	padding: 5px 10px;
	border-bottom: 1px solid #e8e8e8;
}
.red {
	color: red;
}
.group-input {
	width: 100%;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	height: 32px;
	margin-top: 3px;
	display: flex;
}
.group-text {
	flex: 5;
	text-align: left;
	padding-left: 9px;
	line-height: 32px;
	cursor: pointer;
}
.group-icon {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}
.group-icon i {
	color: #808080;
	background-color: #f6f4f4;
	padding: 8px 20px;
	border-radius: 0px 4px 4px 0px;
	border-left: 1px solid #d9d9d9;
}
</style>
